.static-page {
  
  table {
    
    td:first-child {
      width: 34%;
      border-left: 1px solid black;
    }
  
    td {
      width: 52%;
      border-top: 1px solid black;
      border-right: 1px solid black;
    }
  
    td:last-child {
      width: 14%;
      border-right: 1px solid black;
    }
  }
}