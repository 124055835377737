// Margin
$small-margin: 4px;
$base-margin: 12px;
$ms-margin:24px;
$medium-margin:32px;
$large-margin:64px;

// Padding
$small-padding: 4px;
$base-padding: 12px;
$ms-padding:24px;
$medium-padding:32px;
$large-padding:64px;

//border radius
$small-radius:4px;
$base-radius:10px;
$ms-radius:15px;
$medium-radius:20px;

// Font
$primary-font:'Roboto-Regular';
$bold-font:'Roboto-Bold';

// Font size
$title-size:32px;
$secondary-title:24px;
$link-size:16px;
$description-size:14px;
$small-font:12px;
$title:20px;

// Colors
$white:#ffffff;
$black:#000000;
$platinum:#eaeaea;
$battleship-grey:#858585;
$dark-gray:#aaaaaa;
$ash-grey:#b9b9b9;
$red-orange:#ff4e4e;
$manatee:#979797;
$davy-grey: #595959;
$charcoal-grey : #414141;
$verdigris: #51b0be;
$ultramarine-blue:#4154ff;
$dark-blue:#313E49;
$pastel-gray: #cbcbcb;
$mordant-red:#b50000;
$liver:#4e4e4e;
$green:#00A51A;
$dark-green:#0B5057;
$light-gray: #6B6B6B;
$overlay-background:#e0e0e0;
$dawn:#a5a5a5;
$fountain-blue:#4fafbd;
$platinum:#e2e2e2;
$bg:#f7f7f9;
$RipeMango:#fbba2b;
$lavaRed:#e52922;
