.slick-dots {
  bottom: 25px;
}

.slick-dots li button::before {
  color: $dark-gray;
  opacity: 1;
}

.slick-dots li.slick-active button::before {
  color: $red-orange;
}

.nextArrowBtn,.prevArrowBtn{
  display: none;
  position: absolute;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 6px;
  cursor: pointer;
}

.nextArrowBtn{
  right: -36px;
  @media (max-width: 1074px) {
    right: -26px;
  }
  @media (max-width: 1024px) {
    right: -13px;
  }
}
.prevArrowBtn{
  left: -35px;
  @media (max-width: 1074px) {
    left: -25px;
  }
  @media (max-width: 1024px) {
   left: -12px;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-track{
  display: flex;
  margin-left: unset;
  margin-right: unset;
}


.slick-slide
{
    height: inherit;
}

.row-slide.ssr-fix {
  overflow: auto;
  .slick-slider {
    display: inline-flex;

    product-card {
      width: 127px;
    }
  }
}

.ssr-fix::-webkit-scrollbar {
  width: 0px !important;
}

.ssr-fix::-webkit-scrollbar-thumb {
  background-color: inherit;
}