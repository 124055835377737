@font-face {
  font-family: "Roboto-Regular";
  src: url('../../assets/fonts/Roboto-Regular.ttf')
}

@font-face {
  font-family: "Roboto-Bold";
  src: url('../../assets/fonts/Roboto-Bold.ttf')
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(255, 255, 255);
  font-family: "Roboto-Regular";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

input,
button {
  font-family: "Roboto-Regular";
}

p {
  margin: 0;
}

.faq-container {
  mat-expansion-panel {
    background: #161616 !important;
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 10px !important;
    margin: 15px 0 !important;
  
    &.mat-expanded {
      background: #0f0d0d !important;
    }
  }
  .mat-expansion-indicator::after {
    color: #fff !important;
  }
}

#recaptcha-container > div > div {
  margin: auto;
  margin-top: 22px;
}